import { 
  EntityRow, EntityValue, useLang, ContentNode, CellLabel, DataTable, ActiveEntityProvider,
  ContentTitle, DataTableNameValue, MainAside, Video, CitationCell, 
  EntityMultiValueList, HorizontalScroll, PlainLinkButton, EntityLinkValue, SliceLayoutBoundary, 
  WARM_GREY,
  PageHeader, 
} from '@shapeable/ui';

import { RestorationProject } from '@shapeable/kelp-forest-alliance-types';
import { breakpoints, theme } from '@shapeable/theme';
import { Classable, HasChildren, Indicator, MainAsideDetailsLayout, Page } from '@shapeable/types';
import { filter, sortBy, get } from 'lodash';
import * as React from 'react'; // tslint:disable-line
import styled, { css } from 'styled-components';
import { IndicatorValueTable } from './indicator-value-table';
import { BANNER_OVERLAY, PAGE_RESTORATION_PROJECTS } from '../../data';
import { MapPinIcon } from '../elements/map-pin-icon';
import { RestorationProjectHeaderLayout } from './restoration-project-header-layout';
import { CONTENT_BACKGROUND, CONTENT_BACKGROUND_LIGHT } from '../../theme';
import { classNames, formatDateString, formatNumber, locationDMS } from '@shapeable/utils';
import { EntityValueMapLink, useMapUtils } from '@shapeable/maps';

const cls = classNames('restoration-project-layout');


// -------- Types -------->

export type RestorationProjectLayoutProps = MainAsideDetailsLayout & {
  entity?: RestorationProject;
}

export const RestorationProjectLayoutDefaultProps: RestorationProjectLayoutProps = {
};

// -------- Styles -------->



const ContainerStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    color: ${theme.COLOR('text')};
    font-size: ${15/16}em;

    h1.shp--content-title {
      font-size: 1.2em;
      text-transform: none;
      margin-bottom: ${theme.UNIT(2)};
    }
  `,
});

const DetailsStyles = breakpoints({
  base: css`
    
  `,
});


const MainAsideStyles = breakpoints({
  base: css`
    padding: 0;
  `,
  tablet: css`
    padding: ${theme.UNIT(4)} 0;
  `,
});

const AsideStyles = breakpoints({
  base: css`
    padding-top: ${theme.UNIT(4)};
  `,
  desktop: css`
    padding-left: 0;
    padding-right: 0;
  `,
});

const ObservationAsideStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(2)} 0;
  `,
  desktop: css`
    padding: ${theme.UNIT(2)} 0;
  `
});


const AsideItemStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    margin-bottom: ${theme.UNIT(4)};
    padding-bottom: ${theme.UNIT(4)};
  `,
});

const ContactStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});


const CountryStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
    color: ${theme.COLOR('dark')};
    cursor: pointer;
    &:hover {
      color: ${theme.COLOR('link-hover')};
    }
  `,
});

const MainOrganisationContainerStyles = breakpoints({
  base: css`
    ${AsideItemStyles};
  `,
});



const MainOrganisationStyles = breakpoints({
  base: css`  
    color: ${theme.COLOR('dark')};
    cursor: pointer;
    &:hover {
    color: ${theme.COLOR('link-hover')};
  }
    
  `,
});

const DescriptionStyles = breakpoints({

});


const ContentStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)} ${theme.UNIT(0)} ${theme.UNIT(7)};
    ${theme.H_DOT_BG()};
    &:first-child { background: none; }

    h1 {
      color: ${theme.COLOR('secondary')};
      text-transform: uppercase;
      margin-bottom: 1em;
    }
  `,
});

const ObjectiveStyles = breakpoints({
  base: css`
    ${ContentStyles};
    ${theme.H_DOT_BG()};
    &:first-child { background: none; }
  `,
});

const CauseOfDeclineStyles = breakpoints({
  base: css`
    ${ContentStyles};
    ${theme.H_DOT_BG()};
    &:first-child { background: none; }
  `,
});

const ObservationsStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    margin: ${theme.UNIT(4)} 0 0;
    padding: ${theme.UNIT(4)} 0;
    background: ${WARM_GREY};
    width: 100%;
    display: flex;
    justify-content: center;
    color: ${theme.COLOR('text')};
  `,
  desktop: css`
    padding: ${theme.UNIT(6)} 0;
  `
});

const ObservationStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    width: 100%;
    box-sizing: border-box;
    border-top: ${theme.COLOR('line-mid')} 1px solid;
    padding: ${theme.UNIT(3)} ${theme.UNIT(0)} ${theme.UNIT(5)};

    &:first-child {
      border-top: none;
    }
  `,
});

const ObservationMainAsideStyles = breakpoints({
  base: css`
  `,
});

const ActionSummaryStyles = breakpoints({
  base: css`
    line-height: 1.5em;
    ${theme.H_DOT_BG_END()};
    padding: ${theme.UNIT(4)} 0;
  `,
});

const LessonsLearnedStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    line-height: 1.5em;
    padding: ${theme.UNIT(4)} 0;
  `,
});

const ProjectImpactsStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    line-height: 1.5em;
    padding: ${theme.UNIT(4)} 0;
  `,
});

const DisturbanceDescriptionStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    padding: ${theme.UNIT(4)} 0;
  `,
});

const CellHeaderStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(1)};
  `,
});

const ReasonsStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0;
  `,
});

const ObservationReasonsStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
    padding-top: ${theme.UNIT(3)};
  `,
});

const AdvancementStyles = breakpoints({
  base: css`
    font-family: ${theme.FONT('sans')};
    width: 100%;
    box-sizing: border-box;
    margin-top: ${theme.UNIT(1)};
    .shp--entity-row__description {
      padding-top: ${theme.UNIT(1)};
    }
  `,
});


const EcologicalPrincipleStyles = breakpoints({
  base: css`
    ${AdvancementStyles};
    margin-top: ${theme.UNIT(2.5)};
    padding-top: ${theme.UNIT(2.5)};
    ${theme.H_DOT_BG()};

    h2 {
      line-height: 1.2em;
    }
  `,
});


const EcologicalPrinciplesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(6)} 0  ${theme.UNIT(2)};
  `,
});

const SpeciesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
    margin-right: ${theme.UNIT(2)};
  `,
});

const MethodologiesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
    margin-right: ${theme.UNIT(2)};
  `,
});

const ImpactTypesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
    margin-right: ${theme.UNIT(2)};
  `,
});


const DisturbanceTypesStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
    margin-right: ${theme.UNIT(2)};
  `,
});

const MotivationsStyles = breakpoints({
  base: css`
    box-sizing: border-box;
    padding: ${theme.UNIT(3)} 0 ${theme.UNIT(0)};
  `,
});

const ValuesStyles = breakpoints({
  base: css`
    padding: 0 0 ${theme.UNIT(2)};
  `,
});

const TableStyles = breakpoints({
  base: css`
    width: 100%;
    max-width: 800px;
  `,
});

const DataTableStyles = breakpoints({
  base: css`
    ${TableStyles};
    td {
      padding-right: ${theme.UNIT(3)};
    }
    tbody {
      padding-right: 0;
    }
  `,
});


const OrganisationsStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG()};
    margin-top: ${theme.UNIT(4)};
    padding: ${theme.UNIT(5)} 0 0;
     a {
      color: ${theme.COLOR('dark')};
    cursor: pointer;
    &:hover {
      color: ${theme.COLOR('link-hover')};
    };
  };
  `,
});

const OrganisationStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG_END()};
    padding-bottom: ${theme.UNIT(4)};
    margin-bottom: ${theme.UNIT(4)};

    &:last-child {
      background: none;
    }
  `,
});

const TitleStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
    color: ${theme.COLOR('secondary')};
    text-transform: uppercase;
  `,
});

const ListStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const DataStyles = breakpoints({
  base: css`
    padding: ${theme.UNIT(5)} 0 0;
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const TransplantAdherenceMethodStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const TransplantSourceStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const TransplantLifeStageStyles = breakpoints({
  base: css`
    margin-bottom: ${theme.UNIT(2)};
  `,
});

const VideoStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(3)};
  `,
});

const CitationStyles = breakpoints({
  base: css`
    ${theme.H_DOT_BG()};
    font-family: ${theme.FONT('sans')};
    width: 100%;
    box-sizing: border-box;
    padding: ${theme.UNIT(4)} 0 ${theme.UNIT(5)};

    h2 {
      line-height: 1.4em;
    }
  `,
});


const CitationCellStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(1)} 0 0;
  `,
});

const ObservationCitationStyles = breakpoints({
  base: css`
    ${CitationStyles};
    margin-top: ${theme.UNIT(4)};
    border-bottom: 1px solid ${theme.COLOR('line-mid')};
  `,
});
const ObservationCauseOfDeclineStyles = breakpoints({
  base: css`
    ${CauseOfDeclineStyles};
    background: none;
    padding-left: 0;
    padding-right: 0;
  `,
});

const ValueStyles = breakpoints({
  base: css`
    margin-top: ${theme.UNIT(4)};
  `,
});

const HeaderStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const BodyStyles = breakpoints({
  base: css`
    width: 100%;
  `,
});

const ObservationsBodyStyles = breakpoints({
  base: css`
    width: 100%;
    box-sizing: content-box;
  `,
});

const ObservationsContentStyles = breakpoints({
  base: css`
  `,
  tablet: css`
  `,
  desktop: css`
  `,
});


const DMSStyles = breakpoints({
  base: css`
    margin: ${theme.UNIT(2)} 0 ${theme.UNIT(4)};
    ${theme.H_DOT_BG_END()};
    width: 100%;
    padding-bottom: ${theme.UNIT(3)};
    color: ${theme.COLOR('dark')};
    display: flex;
    font-size: ${theme.FONT_SIZE(13)};
    align-items: center;

    div {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
    }

    span, b {
      display: block;
    }

    b {
      padding-top: ${theme.UNIT(1)};
      font-weight:500;
    }

    &:hover {
      .shp--map-marker-icon__fill {
        fill: ${theme.COLOR('link-hover')};
      }
    }
  `,
  tablet: css`
    font-size: ${theme.FONT_SIZE(15)};
  `
});

const DMSIconStyles = breakpoints({
  base: css`
    margin-right: ${theme.UNIT(1)};
    width: 56px;
    height: 56px;
  `,
});



// -------- Components -------->

const My = {
  
  Header: styled(RestorationProjectHeaderLayout)`${HeaderStyles}`,

  Details: styled.div`${DetailsStyles}`,
  Body: styled.div`${BodyStyles}`,

  MainAside: styled(MainAside)`${MainAsideStyles}`,

  Citation: styled(EntityRow)`${CitationStyles}`,
    CitationCell: styled(CitationCell)`${CitationCellStyles}`,

  ObservationCitation: styled.div`${ObservationCitationStyles}`,
    ObservationAside: styled.div`${ObservationAsideStyles}`,

  Value: styled(EntityValue)`${ValueStyles}`,

  Aside: styled.div`${AsideStyles}`,
    Country: styled(EntityValueMapLink)`${CountryStyles}`,
    Contact: styled(EntityValue)`${ContactStyles}`,
    MainOrganisationContainer: styled.div`${MainOrganisationContainerStyles}`,
      MainOrganisation: styled(EntityLinkValue)`${MainOrganisationStyles}`,

  Container: styled.div<RestorationProjectLayoutProps>`${ContainerStyles}`,
    CellHeader: styled(CellLabel)`${CellHeaderStyles}`,

    Title: styled(ContentTitle)`${TitleStyles}`,
    Content: styled(ContentNode)`${ContentStyles}`,
    
    List: styled.div`${ListStyles}`,

    Description: styled(ContentNode)`${DescriptionStyles}`,
    Objective: styled(ContentNode)`${ObjectiveStyles}`,
    CauseOfDecline: styled(ContentNode)`${CauseOfDeclineStyles}`,

    Organisations: styled.div`${OrganisationsStyles}`,
      Organisation: styled(EntityRow)`${OrganisationStyles}`,

    Observations: styled.div`${ObservationsStyles}`,
      ObservationsBody: styled.div`${ObservationsBodyStyles}`,
        ObservationsContent: styled.div`${ObservationsContentStyles}`,
        
      ObservationMainAside: styled(MainAside)`${ObservationMainAsideStyles}`,

      Observation: styled(EntityRow)`${ObservationStyles}`,

      ActionSummary: styled(ContentNode)`${ActionSummaryStyles}`,
      LessonsLearned: styled(ContentNode)`${LessonsLearnedStyles}`,
      ProjectImpacts: styled(ContentNode)`${ProjectImpactsStyles}`,
      DisturbanceDescription: styled(ContentNode)`${DisturbanceDescriptionStyles}`,

      EcologicalPrinciples: styled.div`${EcologicalPrinciplesStyles}`,
        EcologicalPrinciple: styled(EntityRow)`${EcologicalPrincipleStyles}`,

      Species: styled(EntityMultiValueList)`${SpeciesStyles}`,
      Methodologies: styled(EntityMultiValueList)`${MethodologiesStyles}`,
      ImpactTypes: styled(EntityMultiValueList)`${ImpactTypesStyles}`,
      DisturbanceTypes: styled(EntityMultiValueList)`${DisturbanceTypesStyles}`,
      
      
      TransplantAdherenceMethod: styled.div`${TransplantAdherenceMethodStyles}`,
      TransplantSource: styled.div`${TransplantSourceStyles}`,
      TransplantLifeStage: styled.div`${TransplantLifeStageStyles}`,

      Motivations: styled(EntityMultiValueList)`${MotivationsStyles}`,

      Values: styled.div`${ValuesStyles}`,
        Table: styled(IndicatorValueTable)`${TableStyles}`,

    Reasons: styled(EntityMultiValueList)`${ReasonsStyles}`,
    
    ObservationCauseOfDecline: styled(ContentNode)`${ObservationCauseOfDeclineStyles}`,
    ObservationReasons: styled(EntityMultiValueList)`${ObservationReasonsStyles}`,
    
    Data: styled.div`${DataStyles}`, 

    TransplantInfo: styled(DataTable)`${DataTableStyles}`,
    Costs: styled(DataTable)`${DataTableStyles}`,

    Video: styled(Video)`${VideoStyles}`,

       
    DMS: styled(PlainLinkButton)`${DMSStyles}`,
    DMSIcon: styled(MapPinIcon)`${DMSIconStyles}`,

    
};

export const RestorationProjectLayout: React.FC<RestorationProjectLayoutProps> = (props) => {
  const { className, entity } = props;
  const { 
    observations = [], reasons = [], causeOfDecline, organisation, description, openGraph = {},
    objective, organisations = [], motivations = [], species = [], country, contact,
    video, citation, name
  } = entity;

  const hasOrganisations = !!organisations.length;
  const hasSpecies = !!species.length

  const t = useLang();
  const dms = locationDMS(entity);

  const { showEntity } = useMapUtils();

  const dmsOnClick = () => {
    showEntity(entity, {
      showMap: true,
      entityTypeNames: ['RestorationProject'],
      select: true,
      zoom: 11,
    });
  };

  const pageEntity: Page = {
    id: `page-${entity.id}`,
    banners: [{ image: openGraph.image }],
  };

  return (
    <ActiveEntityProvider value={PAGE_RESTORATION_PROJECTS}>
    
    <My.Container className={className}>
      <My.Details>
      <PageHeader variant='overlay' aspectRatio={{ base: 4 / 1 }} overlayColor={BANNER_OVERLAY} entity={pageEntity}>
        <My.Header entity={entity} />
      </PageHeader>
      <SliceLayoutBoundary>

      <My.Body>
      <My.MainAside
        aside={
          <My.Aside>
          {
            dms &&
            <My.DMS onClick={dmsOnClick}>
              <My.DMSIcon />
              <div>
                <span>{t('View on Map')}</span>
              <b>{dms}</b>
              </div>
            </My.DMS>
          }
          {
            country && <My.Country entity={country} />
          }
          {
            contact && <My.Contact label="Primary Contact" entity={contact} />
          }
          {
            organisation && 
              <My.MainOrganisationContainer>
                <My.MainOrganisation imageStyle='logo' label="Primary Organisation" entity={organisation} />
              </My.MainOrganisationContainer>
          }
          <My.Motivations background="#FFF" items={motivations} />
          {
            hasSpecies &&
            <My.Species background="#FFF" items={species} />
          }
          {
            video && <My.Video entity={video} />
          }
          </My.Aside>
        }>
      
        <My.Objective title={t('Restoration Objective:')} entity={objective} />
        <My.Content title={t('Site Selection Criteria:')} entity={description} />

        {
          causeOfDecline && 
          <My.CauseOfDecline entity={causeOfDecline} title={t('Cause Of Decline:')} />
        }

        <My.Reasons headerLabel={t('Key Reasons For Decline:')} background="#FFF" items={reasons} />

        {
          citation &&
          <My.Citation label='Scientific Paper' variant="default" entity={citation}>
            <My.CitationCell urlMaxLength={60} showName={false} isExtended entity={citation} />
          </My.Citation>
        }

        {
          hasOrganisations &&
          <My.Organisations>
            <My.Title>{organisations.length === 1 ? t('Organisation') : t('Organisations')}:</My.Title>
            {
              organisations.map(org => <My.Organisation useLinks imageStyle="logo" showDescription key={org.id} showLabel={false} variant="large" subtitle={org.country && org.country.name} entity={org} />)
            }
          </My.Organisations>
        }

        

      </My.MainAside>
      
      </My.Body>

      </SliceLayoutBoundary>
      </My.Details>


    <My.Observations>
    <SliceLayoutBoundary>
      <My.ObservationsBody>
      <My.ObservationsContent>
      <My.Title>{t('Site Observations')}:</My.Title>
        <div>
        {
        observations.map(observation => {
          const dates = filter([
            observation.startDate && formatDateString(observation.startDate, "Do MMM YYYY"),
            observation.endDate && formatDateString(observation.endDate, "Do MMM YYYY"),
          ]);
          
          const {
            species = [], methodologies = [], reasons = [], impactTypes = [], actionSummary, lessonsLearned, projectImpacts, indicatorValues = [], ecologicalPrinciples = [],
            transplantAdherenceMethod, transplantLifeStage, transplantSource, recordedBy, disturbanceTypes = [], disturbanceDescription, causeOfDecline, areaOfRestoration, distanceToNearestKelpForestInMetres,
            totalCost, capitalCost, planningCost, constructionCost, maintenanceCost, inKindCost, monitoringCost, costYear, costCurrency = 'USD', citation, motivations = [],
          } = observation;

          const hasValues = !!indicatorValues.length;
          const hasEcologicalPrinciples = !!ecologicalPrinciples.length;

          const hasTransplantAdherenceMethod = !!transplantAdherenceMethod;
          const hasTransplantLifeStage = !!transplantLifeStage;
          const hasTransplantSource = !!transplantSource;

          const transplantInfo: DataTableNameValue[] = filter([ 
            hasTransplantAdherenceMethod && {
              name: "Adherence Method",
              value: transplantAdherenceMethod.name,
            },
            hasTransplantLifeStage && {
              name: "Life Stage",
              value: transplantLifeStage.name,
            },
            hasTransplantSource && {
              name: "Source",
              value: transplantSource.name,
            },
          ]);
          
          const costs: DataTableNameValue[] = filter([ 
            costYear && {
              name: "Cost Year",
              value: costYear,
            },
            costCurrency && {
              name: "Cost Currency",
              value: costCurrency,
            },
            totalCost && {
              name: "Total Cost",
              value: formatNumber(totalCost),
            },
            capitalCost && {
              name: "Capital Cost",
              value: formatNumber(capitalCost),
            },
            planningCost && {
              name: "Planning Cost",
              value: formatNumber(planningCost),
            },
            constructionCost && {
              name: "Construction Cost",
              value: formatNumber(planningCost),
            },
            inKindCost && {
              name: "In-Kind Cost",
              value: formatNumber(inKindCost),
            },
            monitoringCost && {
              name: "Monitoring Cost",
              value: formatNumber(monitoringCost),
            },
            maintenanceCost && {
              name: "Maintenance Cost",
              value: formatNumber(maintenanceCost),
            },

            

          ]);

          const hasCosts = !!costs.length;
          const hasTransplantInfo = !!transplantInfo.length;
          
          const areaOfRestorationIndicator: Indicator = !!areaOfRestoration && {
            id: `area-of-restoration-${observation.id}`,
            name: formatNumber(areaOfRestoration),
            __typename: 'Indicator',
          };


          const distanceToNearestKelpForestInMetresIndicator: Indicator = !!distanceToNearestKelpForestInMetres && {
            id: `distance-to-nearest-kelp-forest-in-metres-${observation.id}`,
            name: formatNumber(distanceToNearestKelpForestInMetres),
            __typename: 'Indicator',
          };


          return <My.Observation showMenu label="Observation Date" key={observation.id} name={(dates.join(' – '))} showDescription entity={observation}>

            <My.ObservationMainAside
              aside={
              <My.ObservationAside>

              {
                recordedBy.map(person => (
                  <My.Contact key={person.id} label="Recorded By" entity={person} />
                ))
              }

              <My.Species items={species} background={CONTENT_BACKGROUND_LIGHT} />
              <My.Motivations background={CONTENT_BACKGROUND_LIGHT} items={motivations} />
              <My.ImpactTypes background={CONTENT_BACKGROUND_LIGHT} items={impactTypes} />
              <My.Methodologies background={CONTENT_BACKGROUND_LIGHT} items={methodologies} />
              <My.DisturbanceTypes background={CONTENT_BACKGROUND_LIGHT} items={disturbanceTypes} />
              {
                hasEcologicalPrinciples && 
                <My.EcologicalPrinciples>
                <My.CellHeader>{t('Ecological Principles')}:</My.CellHeader>
                {
                  sortBy(ecologicalPrinciples, ec => ec.number).map(ec => {
                    return (
                      <My.EcologicalPrinciple key={ec.id} variant="smallName" entity={ec} />
                    );
                  })
                }
                </My.EcologicalPrinciples>
              }
              </My.ObservationAside>
              }
            >
              <My.ActionSummary title={t('Action Summary:')} entity={actionSummary} />
              <My.LessonsLearned title={t('Lessons Learned:')} entity={lessonsLearned} />
              <My.ProjectImpacts title={t('Project Outcomes:')} entity={projectImpacts} />
              <My.DisturbanceDescription title={t('Nature of Disturbance:')} entity={disturbanceDescription} />

              {
                causeOfDecline && 
                <My.ObservationCauseOfDecline entity={causeOfDecline} />
              }

              {
                <My.ObservationReasons headerLabel={t('Key Reasons For Decline:')} background={CONTENT_BACKGROUND_LIGHT} items={reasons} />
              }
                
              {
                citation && citation?.id !== entity?.citation?.id &&
                <My.ObservationCitation>
                  <My.CellHeader>{t("Scientific Paper")}:</My.CellHeader>
                  <My.CitationCell urlMaxLength={60} isExtended entity={citation} />
                </My.ObservationCitation>
              }


              {
                areaOfRestorationIndicator && 
                <My.Value label="Area of Restoration (Ha)" entity={areaOfRestorationIndicator} />
              }


              {
                distanceToNearestKelpForestInMetresIndicator && 
                <My.Value variant='card' label="Distance to nearest kelp forest (in metres)" entity={distanceToNearestKelpForestInMetresIndicator} />
              }

              <My.Data>
                <My.Title>{t('Indicator Data:')}</My.Title>

              {
                hasValues && 
                <My.Values>
                  <HorizontalScroll>
                  <My.Table items={indicatorValues} />
                  </HorizontalScroll>
                </My.Values>
              }
              
              {
                hasTransplantInfo &&
                <My.TransplantInfo title={"Transplant Info"} values={transplantInfo} cellSpacing={0} />
              }

              {
                hasCosts &&
                <HorizontalScroll>
                <My.Costs title={"Costings"} values={costs} cellSpacing={0} />
                </HorizontalScroll>
              }
              </My.Data>
            </My.ObservationMainAside>
          </My.Observation>
        }
        )
        }
        </div>
        </My.ObservationsContent>
        </My.ObservationsBody>
      </SliceLayoutBoundary>
      </My.Observations>
      </My.Container>

    </ActiveEntityProvider>
  );
};

RestorationProjectLayout.defaultProps = RestorationProjectLayoutDefaultProps;
